<template>
  <div class="fnol-edit-view fill-height">
    <Preloader :value="claimEditPageParams.loading" fullscreen />
    <v-container
      v-if="claimEditPageParams.ready"
      class="fnol-edit-view__container"
      fill-height
      align-start
      align-content-start
    >
      <v-row class="fnol-edit-view__header" no-gutters>
        <v-col>
          <div
            v-if="claimEditPageParams.organizationLogo"
            class="fnol-edit-view__header-logo-wrapper"
          >
            <Logo :src="claimEditPageParams.organizationLogo" large />
          </div>
          <div v-if="claimEditPageTitle" class="fnol-edit-view__header-title-wrapper">
            <PageSubtitle>{{ claimEditPageTitle }}</PageSubtitle>
          </div>
          <div
            v-if="claimEditPageParams.complianceWarning"
            class="fnol-edit-view__header-text-wrapper"
          >
            <p
              class="fnol-edit-view__header-text my-0"
              v-html="claimEditPageParams.complianceWarning"
            ></p>
          </div>
        </v-col>
      </v-row>
      <v-row class="fnol-edit-view__body mt-4 mt-md-10" no-gutters>
        <v-col>
          <component
            v-bind.sync="form"
            @addHealthInsuranceForm="addHealthInsuranceSubForm"
            @claimsInfoDisclaimerClick="showClaimsInfoDisclaimer"
            :is="claimEditComponent"
            :formParams="formParams"
            @deleteHealthInsuranceForm="deleteHealthInsuranceSubForm"
            @fieldInput="handleFormFieldInput"
            ref="form"
          ></component>
        </v-col>
      </v-row>
      <v-row class="fnol-edit-view__footer-wrapper my-4 mt-md-6 mb-md-8" no-gutters>
        <v-col cols="12" class="fnol-edit-view__footer">
          <div class="d-flex flex-column flex-md-row justify-md-end">
            <v-btn @click="submitForm" :disabled="submitBtnDisabled">
              Save
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <ClaimsTermsDialog v-model="claimsInfoDisclaimerDialogShown" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { fileUpload, claimEditPageParams, scrollUtils, claimForms } from '@/mixins';
import ClaimsTermsDialog from '@/components/modals/ClaimsTermsDialog';
import Preloader from '@/components/Preloader';
import Logo from '@/components/Logo';
import PageSubtitle from '@/components/typography/PageSubtitle';
import {
  CLAIM_FORM_FIELD_ALIASES,
  USASA_CLAIM_FORM_FIELD_ALIASES,
  OLYMPIC_CLAIM_FORM_FIELD_ALIASES,
  INJURED_PERSON_TYPES,
  NAGHA_CLAIM_FORM_FIELD_ALIASES,
  SPORTS_UNION_TYPES,
} from '@/utils/constants';
import { getClaimFormFromApiPayload } from '@/utils/helpers/app/claims';
import getOlympicClaimFormFromApiPayload from '@/utils/helpers/app/claimsOlympic/getClaimFormFromApiPayload';
import getUsasaClaimFormFromApiPayload from '@/utils/helpers/app/claimsUsasa/getClaimFormFromApiPayload';
import getNaghaClaimFormFromApiPayload from '@/utils/helpers/app/claimsNagha/getClaimFormFromApiPayload';
import getCrawfordClaimFormFromApiPayload from '@/utils/helpers/app/claimsCrawford/getClaimFormFromApiPayload';

const CLAIMANT_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.CLAIMANT;
const COACH_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.COACH;
const DISCLAIMERS_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.DISCLAIMERS;
const INCIDENT_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.INCIDENT;
const INSURANCE_FIELD_ALIASES = CLAIM_FORM_FIELD_ALIASES.INSURANCE;
const HEALTH_FIELD_ALIASES = NAGHA_CLAIM_FORM_FIELD_ALIASES.HEALTH;
const NAGHA_INCIDENT_FIELD_ALIASES = NAGHA_CLAIM_FORM_FIELD_ALIASES.INCIDENT;

export default {
  name: 'FNOLEdit',
  mixins: [
    fileUpload,
    claimEditPageParams,
    scrollUtils({
      yOffset: 20,
    }),
    claimForms,
  ],
  components: {
    ClaimForm: () => import('@/components/forms/ClaimForm/index'),
    ClaimFormOlympic: () => import('@/components/forms/ClaimFormOlympic/index'),
    ClaimFormUsasa: () => import('@/components/forms/ClaimFormUsasa/index'),
    ClaimFormNagha: () => import('@/components/forms/ClaimFormNagha/index'),
    ClaimFormColorado: () => import('@/components/forms/ClaimFormColorado/index'),
    ClaimFormFysa: () => import('@/components/forms/ClaimFormFysa/index'),
    ClaimFormCrawford: () => import('@/components/forms/ClaimFormCrawford/index'),
    ClaimsTermsDialog,
    Preloader,
    Logo,
    PageSubtitle,
  },
  data() {
    return {
      claimsInfoDisclaimerDialogShown: false,
      form: {
        // claimant info
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STATE]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_STREET]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ZIP]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_DOB]: null,
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_EMAIL]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_GENDER]: null,
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_FIRST_NAME]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_LAST_NAME]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_MIDDLE_INITIAL]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_FATHERS_FULL_NAME]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_MOTHERS_FULL_NAME]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ROLE_IN_ORG]: null,
        // coach info
        [COACH_FIELD_ALIASES.COACH_INJURED_TREATED_BY_OFFICIALS]: false,
        [COACH_FIELD_ALIASES.SPORTS_ADMIN]: '',
        [COACH_FIELD_ALIASES.COACH_INJURED_CLUB_NAME]: '',
        [COACH_FIELD_ALIASES.COACH_INCIDENT_TERRITORY]: '',
        [COACH_FIELD_ALIASES.COACH_EMAIL]: '',
        [COACH_FIELD_ALIASES.COACH_FIRST_NAME]: '',
        [COACH_FIELD_ALIASES.COACH_LAST_NAME]: '',
        [USASA_CLAIM_FORM_FIELD_ALIASES.COACH.COACH_EMAIL]: '',
        [USASA_CLAIM_FORM_FIELD_ALIASES.COACH.COACH_FIRST_NAME]: '',
        [USASA_CLAIM_FORM_FIELD_ALIASES.COACH.COACH_LAST_NAME]: '',
        [COACH_FIELD_ALIASES.COACH_PHONE]: '',
        [COACH_FIELD_ALIASES.COACH_SIGNATURE]: '',
        // incident details
        [INCIDENT_FIELD_ALIASES.INCIDENT_BODY_PART_INJURED]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_BODY_SIDE_INJURED]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_CONTACT_TYPE]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_DATE]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_DESCRIPTION]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_NAME]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_TYPE]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_TYPE]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_LOCATION]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_MEMBERSHIP_TYPE]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_MEMBERSHIP_OTHER_TYPE]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_ONSITE_CARE_RECEIVED]: false,
        [INCIDENT_FIELD_ALIASES.INCIDENT_OTHER_CONTACT_TYPE]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_PLAYING_SURFACE]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_SEVERITY]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_TAKEN_TO_HOSPITAL_BY_AMBULANCE]: false,
        [INCIDENT_FIELD_ALIASES.INCIDENT_TIME]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_SIGNATURE]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_FILES]: [],
        [INCIDENT_FIELD_ALIASES.INCIDENT_NOTE]: '',
        // insurance info
        [INSURANCE_FIELD_ALIASES.INSURANCE_COMPANY_NAME]: '',
        [INSURANCE_FIELD_ALIASES.INSURANCE_POLICYHOLDER_NAME]: '',
        [INSURANCE_FIELD_ALIASES.INSURANCE_POLICY_NUMBER]: '',
        [INSURANCE_FIELD_ALIASES.INSURANCE_GROUP_NUMBER]: '',
        // health insurance info
        healthInsuranceForms: {},
        providerInformation: {},

        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ADDITIONAL]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_ZIP]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_ADDRESS_COUNTRY]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_HOME_PHONE]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_WORK_PHONE]: '',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_STATUS]: 'SINGLE',
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_INJURED_PERSON_TYPE]: INJURED_PERSON_TYPES.PARTICIPANT,
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_MEDICAID_BENEFICIARY]: false,
        [CLAIMANT_FIELD_ALIASES.CLAIMANT_SS_NOR_HID_NUMBER]: '',
        [DISCLAIMERS_FIELD_ALIASES.READ_AND_AGREE_TO_CLAIMS_INFO]: false,
        [INCIDENT_FIELD_ALIASES.INCIDENT_DATE]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_TIME]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_ACCIDENT_OCCUR]: null,
        [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_AT]: '',
        [NAGHA_INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_OCCURED_AT_OTHER]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_EVENT_NAME_AND_TYPE]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_INJURY_DESCRIPTION]: '',
        [INCIDENT_FIELD_ALIASES.INCEDENT_NAME_OF_TEAM]: '',
        [INCIDENT_FIELD_ALIASES.INCEDENT_CLAIMANT_CHECKBOX1]: false,
        [INCIDENT_FIELD_ALIASES.INCEDENT_CLAIMANT_CHECKBOX2]: false,
        [INCIDENT_FIELD_ALIASES.INCEDENT_CLAIMANT_SIGNATURE]: '',
        [INCIDENT_FIELD_ALIASES.INCEDENT_NAME_OF_LEAGUE]: '',
        [INCIDENT_FIELD_ALIASES.INCIDENT_RESULT_IN_DEATH]: false,
        [HEALTH_FIELD_ALIASES.HEALTH_CLAIMANT_HAS_HEALTHCARE]: false,
      },
    };
  },
  watch: {
    [`form.${CLAIMANT_FIELD_ALIASES.CLAIMANT_CURRENTLY_ENROLLED_ACCIDENT_PLAN}`]: {
      handler(newVal) {
        if (newVal) {
          this.initHealthInsuranceSubForms();
        } else {
          this.resetHealthInsuranceSubForms();
        }
      },
      immediate: true,
    },
  },
  computed: {
    claimEditComponent() {
      if (this.claimEditPageParams.entity.sportsUnion.form === SPORTS_UNION_TYPES.DEFAULT) {
        return 'ClaimForm';
      }
      if (this.claimEditPageParams.entity.sportsUnion.form === SPORTS_UNION_TYPES.OLYMPIC) {
        return 'ClaimFormOlympic';
      }
      if (this.claimEditPageParams.entity.sportsUnion.form === SPORTS_UNION_TYPES.USASA) {
        return 'ClaimFormUsasa';
      }
      if (this.claimEditPageParams.entity.sportsUnion.form === SPORTS_UNION_TYPES.FYSA) {
        return 'ClaimFormFysa';
      }
      if (this.claimEditPageParams.entity.sportsUnion.form === SPORTS_UNION_TYPES.NAGHA) {
        return 'ClaimFormNagha';
      }
      if (this.claimEditPageParams.entity.sportsUnion.form === SPORTS_UNION_TYPES.COLORADOMTB) {
        return 'ClaimFormColorado';
      }
      if (this.claimEditPageParams.entity.sportsUnion.form === SPORTS_UNION_TYPES.CRAWFORD) {
        return 'ClaimFormCrawford';
      }

      return 'ClaimForm';
    },
    formParams() {
      return {
        loadingFiles: this.fileUpload.loading,
        organizationName: this.claimEditPageParams.organizationName,
        stateAdmins: this.claimEditPageParams.stateAdmins,
        hiddenFields: this.claimIsOlympic
          ? [
              OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_AUTHORIZE_MEDICAL_PAYMENTS,
              OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT.INCIDENT_AUTHORIZE_HERIBAL_ANY_INSURANCE,
              OLYMPIC_CLAIM_FORM_FIELD_ALIASES.INCIDENT
                .INCIDENT_AUTHORIZE_HERIBAL_ANY_COMMUNICATIONS,
              OLYMPIC_CLAIM_FORM_FIELD_ALIASES.DISCLAIMERS.READ_AND_AGREE_TO_CLAIMS_INFO,
            ]
          : [
              DISCLAIMERS_FIELD_ALIASES.READ_AND_AGREE_TO_CLAIMS_INFO,
              INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_TPA_PROCESSING,
            ],
      };
    },
    submitBtnDisabled() {
      const checkMustAgreeToClaimsInfo = () =>
        (!this.claimIsOlympic &&
          !this.formFieldIsHidden(DISCLAIMERS_FIELD_ALIASES.READ_AND_AGREE_TO_CLAIMS_INFO) &&
          !this.form[DISCLAIMERS_FIELD_ALIASES.READ_AND_AGREE_TO_CLAIMS_INFO]) ||
        (!this.formFieldIsHidden(
          OLYMPIC_CLAIM_FORM_FIELD_ALIASES.DISCLAIMERS.READ_AND_AGREE_TO_CLAIMS_INFO
        ) &&
          !this.form[OLYMPIC_CLAIM_FORM_FIELD_ALIASES.DISCLAIMERS.READ_AND_AGREE_TO_CLAIMS_INFO]);
      const checkMustAgreeToTpaProcessing = () =>
        !this.claimIsOlympic &&
        !this.formFieldIsHidden(INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_TPA_PROCESSING) &&
        !this.form[INCIDENT_FIELD_ALIASES.INCIDENT_AUTHORIZE_TPA_PROCESSING];
      return checkMustAgreeToClaimsInfo() || checkMustAgreeToTpaProcessing();
    },
  },
  methods: {
    ...mapActions('claims', ['submitFNOLEditForm']),
    handleFormFieldInput({ field, value }, formKey = 'form') {
      const fieldsArray = field.split('.');
      if (fieldsArray.length > 2) {
        this.handleSubFormFieldInput({ field, value });
      } else if (fieldsArray.length === 2) {
        const [key, subKey] = fieldsArray;
        this[formKey][key][subKey] = value;
      }
      if (field === INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_FILES) {
        this.handleClaimantFilesAttachement({ field, value });
      }
    },
    formFieldIsHidden(field) {
      if (this.formParams instanceof Object) {
        const { hiddenFields } = this.formParams;
        return hiddenFields instanceof Array && hiddenFields.length && hiddenFields.includes(field);
      }
      return false;
    },
    async handleClaimantFilesAttachement({ field, value }) {
      if (field === INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_FILES) {
        try {
          const uploads = await this.uploadMultipleFiles(value);
          return !!uploads;
        } catch (error) {
          console.error(error);
          this.$dialog.error({ error });
          return false;
        }
      }
      return false;
    },
    async initClaimEditForm() {
      const initialized = await this.initClaimEditPageParams();
      if (initialized) {
        try {
          this.populateFormFromClaim(this.claimEditPageParams.entity);
        } catch (error) {
          this.$dialog.error({ error });
        }
      }
    },
    populateFormFromClaim(claim) {
      let form;

      if (this.claimEditPageParams.entity.sportsUnion.form === SPORTS_UNION_TYPES.DEFAULT) {
        form = getClaimFormFromApiPayload(claim);
        if (
          form[INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_FILES] &&
          form[INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_FILES].length
        ) {
          this.fileUpload.uploads = form[INCIDENT_FIELD_ALIASES.INCIDENT_VICTIM_FILES];
        }
      }
      if (this.claimEditPageParams.entity.sportsUnion.form === SPORTS_UNION_TYPES.OLYMPIC) {
        form = getOlympicClaimFormFromApiPayload(claim);
      }
      if (this.claimEditPageParams.entity.sportsUnion.form === SPORTS_UNION_TYPES.USASA) {
        form = getUsasaClaimFormFromApiPayload(claim);
      }
      if (this.claimEditPageParams.entity.sportsUnion.form === SPORTS_UNION_TYPES.FYSA) {
        form = getUsasaClaimFormFromApiPayload(claim);
      }
      if (this.claimEditPageParams.entity.sportsUnion.form === SPORTS_UNION_TYPES.NAGHA) {
        form = getNaghaClaimFormFromApiPayload(claim);
      }
      if (this.claimEditPageParams.entity.sportsUnion.form === SPORTS_UNION_TYPES.COLORADOMTB) {
        form = getUsasaClaimFormFromApiPayload(claim);
      }
      if (this.claimEditPageParams.entity.sportsUnion.form === SPORTS_UNION_TYPES.CRAWFORD) {
        form = getCrawfordClaimFormFromApiPayload(claim);
      }

      if (form instanceof Object) {
        this.form = {
          ...this.form,
          ...form,
        };
      }
    },
    showClaimsInfoDisclaimer() {
      this.claimsInfoDisclaimerDialogShown = true;
    },
    async submitForm() {
      if (this.$refs.form) {
        const canSubmit = this.$refs.form.submit();
        if (canSubmit) {
          try {
            const payload = {
              ...this.form,
              sportsUnionId: this.claimEditPageParams.organizationId,
            };
            if (this.uploadIds instanceof Array && this.uploadIds.length) {
              payload.filesIds = this.uploadIds;
            }
            const success = await this.submitFNOLEditForm({
              id: this.$route.params.id,
              form: payload,
              formType:
                this.claimEditPageParams.entity.sportsUnion.form || SPORTS_UNION_TYPES.DEFAULT,
            });
            if (success) {
              await this.$dialog.info({
                title: 'Success',
                description: "You've successfully edited a FNOL",
              });
              this.$router.push({
                name: 'fnols',
              });
            } else {
              throw new Error('There was en error while submitting your FNOL');
            }
            return success;
          } catch (error) {
            this.$dialog.error({ error });
            return error;
          }
        } else {
          this.scrollToFirstError();
        }
      }
      return false;
    },
  },
  mounted() {
    this.initHealthInsuranceSubForms();
    this.initClaimEditForm();
  },
};
</script>

<style lang="scss" scoped>
.fnol-edit-view {
  background-color: $background-lightest;
}
.fnol-edit-view__container {
  max-width: 780px;
}
.fnol-edit-view__header {
  padding-top: 90px;
}
.fnol-edit-view__header-logo-wrapper {
  display: flex;
  margin-bottom: 40px;
}
.fnol-edit-view__header-text-wrapper {
  margin-top: 12px;
}
.fnol-edit-view__header-text {
  color: $text-semi-dark;
  font-size: 14px;
  line-height: 1.14;
}
</style>
