/* eslint no-prototype-builtins: 0 */

import {
  CRAWFORD_CLAIM_FORM_FIELD_API_ALIASES,
  CRAWFORD_CLAIM_FORM_HEALTH_FIELD_API_ALIASES,
} from '@/utils/constants';
import { flattenObject } from '@/utils/helpers/object';

const reverseClaimFormFieldApiAliases = payload => {
  if (!(payload instanceof Object)) {
    throw new Error('The provided "payload" argument is not a valid Object');
  }
  return Object.entries(payload).reduce((acc, [field, value]) => {
    acc[value] = field;
    return acc;
  }, {});
};

const CLAIM_API_PAYLOAD_FIELD_ALIASES = reverseClaimFormFieldApiAliases(
  CRAWFORD_CLAIM_FORM_FIELD_API_ALIASES
);

const CLAIM_API_PAYLOAD_HEALTH_FIELD_ALIASES = reverseClaimFormFieldApiAliases(
  CRAWFORD_CLAIM_FORM_HEALTH_FIELD_API_ALIASES
);

const convertClaimApiPayloadToAliases = payload => {
  if (!(payload instanceof Object)) {
    throw new Error('The provided "payload" argument is not a valid Object');
  }

  const { properties, healthInsurancePlan, ...rest } = payload;

  if (Array.isArray(properties) && properties.length) {
    [rest.property] = properties;
  }

  const result = Object.entries(rest).reduce((acc, [field, value]) => {
    if (CLAIM_API_PAYLOAD_FIELD_ALIASES[field]) {
      if (field === 'files') {
        acc[CLAIM_API_PAYLOAD_FIELD_ALIASES[field]] = value.map(p => ({ ...p, size: 1 }));
      } else {
        acc[CLAIM_API_PAYLOAD_FIELD_ALIASES[field]] = value;
      }
    } else if (value instanceof Object) {
      const flattenedValueObject = flattenObject({
        [field]: value,
      });
      Object.entries(flattenedValueObject).forEach(([flatField, flatValue]) => {
        if (CLAIM_API_PAYLOAD_FIELD_ALIASES[flatField]) {
          acc[CLAIM_API_PAYLOAD_FIELD_ALIASES[flatField]] = flatValue;
        }
      });
    } else {
      acc[field] = value;
    }
    return acc;
  }, {});

  if (Array.isArray(healthInsurancePlan) && healthInsurancePlan.length) {
    const healthInsuranceForms = healthInsurancePlan.reduce((acc, plan, index) => {
      const mappedPlan = Object.entries(plan).reduce((planForm, [field, value]) => {
        if (CLAIM_API_PAYLOAD_HEALTH_FIELD_ALIASES[field]) {
          return { ...planForm, [CLAIM_API_PAYLOAD_HEALTH_FIELD_ALIASES[field]]: value };
        }
        return planForm;
      }, {});

      Object.assign(acc, { [`plan_${index}`]: mappedPlan });
      return acc;
    }, {});

    result.healthInsuranceForms = healthInsuranceForms;
  }

  return result;
};

export default (payload, fields = []) => {
  if (!(payload instanceof Object)) {
    throw new Error('The provided "payload" argument is not a valid Object');
  }
  const aliasedForm = convertClaimApiPayloadToAliases(payload);
  if (fields instanceof Array && fields.length) {
    return fields.reduce((acc, field) => {
      if (aliasedForm.hasOwnProperty(field)) {
        acc[field] = aliasedForm[field];
      }
      return acc;
    }, {});
  }
  return aliasedForm;
};
